<!--<pre> {{ participants | json }} </pre>-->
<!--aaaa-->
<!--<div *ngFor="let participant of participants">-->
<!--    {{ participant.participant_id }}-->
<!--</div>-->
<!--bbbb-->
<div class="d-flex table-scrollable">
    <div class="table-scrollable__fixed">
        <div class="table-scrollable__fixed-shadow"></div>
        <table class="table-scrollable__fixed__table stage-table golf-table-list">
            <thead>
                <tr class="bg-light">
                    <th class="table-scrollable__table-th font-weight-normal">#</th>
                    <th class="table-scrollable__table-th table-scrollable__fixed__table__text font-weight-normal"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="table-scrollable__table-row app-cursor"
                    [class.active]="activeParticipantId === participant.id"
                    *ngFor="let participant of data.participants | slice : 0 : slice; trackBy: trackByIndex"
                    (click)="openGolfParticipant(data, participant)"
                >
                    <td class="table-scrollable__table-td">
                        <ng-container *ngIf="participant.status; else position">{{ participant.status }}</ng-container>
                        <ng-template #position>{{ participant.position !== null ? participant.position + '.' : '' }}</ng-template>
                    </td>
                    <td class="table-scrollable__table-td">
                        <img
                            *ngIf="participant.iso"
                            src="/img/flags/1x1/{{ participant.iso | lowercase }}.svg"
                            alt=""
                            class="rounded-circle mr-2"
                            height="20"
                        />
                        <img
                            *ngIf="!participant.iso"
                            src="/img/default-avatar.png"
                            alt=""
                            class="rounded-circle mr-2"
                            height="20"
                        />
                        {{ participant.name }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-scrollable__data flex-grow-1" tableScroll>
        <table class="table-scrollable__data__table stage-table golf-table-list">
            <thead>
                <tr class="bg-light">
                    <!--                <th *ngIf="stage.hasTeams" class="table-scrollable__table-th table-scrollable__data__table-th text-left">-->
                    <!--                    {{ 'web.team' | translate }}-->
                    <!--                </th>-->
                    <!--                <ng-container *ngFor="let valueType of valueTypes">-->
                    <!--                    <th class="table-scrollable__table-th table-scrollable__data__table-th">-->
                    <!--                        {{ valueType }}-->
                    <!--                    </th>-->
                    <!--                </ng-container>-->
                    <th class="table-scrollable__table-th table-scrollable__data__table-th">{{ 'web.score' | translate }}</th>
                    <th
                        *ngFor="let round of data.participants[0]?.rounds; index as i; trackBy: trackByIndex"
                        class="table-scrollable__table-th table-scrollable__data__table-th"
                    >
                        {{ i + 1 }}
                    </th>
                    <!--                    <th class="table-scrollable__table-th table-scrollable__data__table-th">Points</th>-->
                    <!--                    <th class="table-scrollable__table-th table-scrollable__data__table-th">Status</th>-->
                    <th class="table-scrollable__table-th table-scrollable__data__table-th">{{ 'web.strokes' | translate }}</th>
                    <!--                    <th class="table-scrollable__table-th table-scrollable__data__table-th">Tied</th>-->
                    <!--                    <th class="table-scrollable__table-th table-scrollable__data__table-th">Money</th>-->
                </tr>
            </thead>
            <tbody>
                <ng-container>
                    <tr
                        class="table-scrollable__table-row app-cursor"
                        [class.active]="activeParticipantId === participant.id"
                        *ngFor="let participant of data.participants | slice : 0 : slice; trackBy: trackByIndex"
                        (click)="openGolfParticipant(data, participant)"
                    >
                        <!--                    <td *ngIf="stage.hasTeams" class="table-scrollable__table-td table-scrollable__data__table-td text-left">-->
                        <!--                        {{ participant.team_name }}-->
                        <!--                    </td>-->
                        <!--                    <ng-container *ngFor="let valueType of valueTypes">-->
                        <!--                        <td class="table-scrollable__table-td table-scrollable__data__table-td">-->
                        <!--                            {{ participant.values[valueType] }}-->
                        <!--                        </td>-->
                        <!--                    </ng-container>-->

                        <td class="table-scrollable__table-td table-scrollable__data__table-td">
                            <span [@score]="participant.score">{{ participant.score }}</span>
                        </td>
                        <!--                    <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ participant.points }}</td>-->
                        <!--                    <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ participant.status }}</td>-->
                        <!--                    <td *ngFor="let round of participant.rounds; trackBy: trackByIndex" class="table-scrollable__table-td table-scrollable__data__table-td">{{ round.strokes }}</td>-->
                        <td
                            *ngFor="let round of data.participants[0].rounds; index as i; trackBy: trackByIndex"
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                        >
                            <span [@score]="participant?.rounds[i] ? participant?.rounds[i].strokes : '-'">
                                {{ participant?.rounds[i] ? participant?.rounds[i].strokes : '-' }}
                            </span>
                        </td>

                        <td class="table-scrollable__table-td table-scrollable__data__table-td">
                            <span [@score]="participant.strokes">{{ participant.strokes }}</span>
                        </td>
                        <!--                    <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ participant.tied }}</td>-->
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<div class="text-center mt-3 mb-4 ng-star-inserted" *ngIf="!isMore">
    <button class="btn btn-outline-secondary btn-sm px-5" (click)="slice = 10000">
        {{ 'web.more' | translate }}
    </button>
</div>
