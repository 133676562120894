<div class="container bg-light position-relative">
    <div class="row">
        <div *ngIf="showList" class="col-lg-6 bg-white p-3 p-lg-0">
            <div class="nav-wrapper app-mob-nav-wrapper">
                <div class="app-mob-nav-shadow"></div>
                <ul
                    class="nav nav-tabs nav-tabs--shadows nav-justified--mobile font-weight-bold"
                    id="tabs-left"
                    role="tablist"
                >
                    <li
                        *ngIf="!deviceService.isMobile()"
                        class="nav-item ml-auto bg-white d-flex align-items-center px-2 app-mob-calendar"
                    >
                        <app-calendar class="d-flex align-items-center" (onChange)="dateChange($event)"></app-calendar>
                    </li>
                </ul>
            </div>
            <div class="d-md-none mb-2 dropdown-calendar">
                <app-calendar [mobile]="true" (onChange)="dateChange($event)"></app-calendar>
            </div>
            <div class="tab-content bg-white p-3 position-relative">
                <div class="tab-pane active" id="tab1-content" role="tabpanel" aria-labelledby="tab1">
                    <div class="table-sport mx-n3 mx-sm-0">
                        <div *ngIf="loaded; else elseBlock" class="table-sport__content">
                            <ng-container *ngIf="loadedError; else loadBlock">
                                <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                    <div class="text-center pt-7 app-match-no-data">
                                        <svg class="icon icon--64 mb-4">
                                            <use [attr.xlink:href]="'#icon-' + sportName" />
                                        </svg>
                                        <h4 class="font-weight-bold">{{ 'web.error_data_load' | translate }}</h4>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #loadBlock>
                                <ng-container *ngIf="data.length > 0">
                                    <div
                                        *ngFor="let golfTournament of data; index as i; trackBy: trackByIndex"
                                        class="table-sport mx-n3 mx-sm-0 mb-3"
                                    >
                                        <h6
                                            *ngIf="golfTournament.show_category"
                                            class="table-sport__head bg-dark text-white"
                                            (click)="openCategory(golfTournament.tour_alias)"
                                            data-toggle="collapse"
                                            [class.collapsed]="false"
                                            [attr.data-target]="'#collapse-category-' + golfTournament.tour_alias"
                                            role="button"
                                            [attr.aria-expanded]="true"
                                            [attr.aria-controls]="'collapse-category-' + golfTournament.tour_alias"
                                        >
                                            <img src="" alt="" width="20" class="mr-1" />
                                            <span class="table-sport__head__text">{{ golfTournament.tour_name }}</span>
                                        </h6>

                                        <div
                                            class="table-sport__content collapse"
                                            [class.show]="true"
                                            [attr.id]="'collapse-category-' + golfTournament.tour_alias"
                                        >
                                            <app-golf
                                                (onSelectStage)="loadStage($event)"
                                                [golfTournament]="golfTournament"
                                                [categoryName]="golfTournament.name"
                                                [sportId]="sportId"
                                                [sportName]="sportName"
                                            ></app-golf>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="!loadedError && data.length == 0">
                                        <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                            <div class="text-center pt-7 app-match-no-data">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">
                                                    {{ 'web.no_tournaments_found' | translate }}
                                                </h4>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="data.latest">
                                    <ng-container *ngIf="type == 'all'; else noData">
                                        <div class="tab-pane" id="tab2-content" role="tabpanel" aria-labelledby="tab2">
                                            <div class="text-center pt-7 app-match-no-data">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">
                                                    {{ 'web.no_tournaments_found' | translate }}
                                                </h4>
                                                <a
                                                    *ngIf="data.latest"
                                                    [routerLink]="['./', { date: setDate(data.latest) }]"
                                                    class="btn btn-primary mx-2 py-1 px-4"
                                                    ><span class="d-block small mb-n1"
                                                        >{{ 'web.latest_event_golf' | translate }}
                                                    </span>
                                                    {{ data.latest | livDate : 'localDate' }}</a
                                                >
                                                <a
                                                    *ngIf="data.upcoming"
                                                    [routerLink]="['./', { date: setDate(data.upcoming) }]"
                                                    class="btn btn-primary mx-2 py-1 px-4"
                                                    ><span class="d-block small mb-n1">{{
                                                        'web.upcomming_event_golf' | translate
                                                    }}</span>
                                                    {{ data.upcoming | livDate : 'localDate' }}</a
                                                >
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #noData>
                                        <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                            <div class="text-center pt-7 app-match-no-data">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">
                                                    {{ 'web.no_tournaments_found' | translate }}
                                                </h4>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </ng-template>
                        </div>
                        <ng-template #elseBlock>
                            <app-page-loader></app-page-loader>
                        </ng-template>
                    </div>

                    <app-alpha-filter
                        *ngIf="alphaAvailLetters.length > 0"
                        class="d-none d-lg-block"
                        [availLetters]="alphaAvailLetters"
                        (onDataChange)="alphaFilter = $event"
                    ></app-alpha-filter>
                </div>

                <div
                    *ngIf="('sport-info.' + sportName | translate).search('sport-info.' + sportName) == -1"
                    class="card shadow border-0 mt-5"
                >
                    <div class="app-info-card card-body p-0 p-md-5">
                        <div class="media">
                            <svg class="icon icon--32 mr-3">
                                <use href="#icon-bulp-2" />
                            </svg>
                            <div
                                class="media-body mt-2"
                                [innerHTML]="'sport-info.' + sportName | translate | markdown"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 p-0 p-lg-3">
            <!--            <ng-container *ngIf="!deviceService.isMobile()">-->

            <app-golf-participant-tournament></app-golf-participant-tournament>

            <app-ranking *ngIf="!deviceService.isMobile()" [sportName]="sportName" [sportId]="sportId"></app-ranking>

            <!--                    <app-golf-right-col-->
            <!--                        [standingsStageId]="stageId"-->
            <!--                        [stageParticipantStageId]="stageParticipantStageId"-->
            <!--                        [stageParticipantParticipantId]="stageParticipantParticipantId"-->
            <!--                    ></app-golf-right-col>-->

            <!--            </ng-container>-->
        </div>
    </div>

    <aside class="nav-sports d-none d-xl-block">
        <nav class="nav flex-column bg-light">
            <app-sport-navigation
                type="aside"
                [disabled]="this.first"
                [limit]="asideLimit"
                [sportId]="sportId"
            ></app-sport-navigation>
            <a
                class="nav-link nav-link--more text-uppercase text-reset app-cursor"
                [class.nav-link--more]="isAsideDefault()"
                [class.nav-link--less]="!isAsideDefault()"
                (click)="toggleAssideLimit()"
            >
                <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                <span *ngIf="!isAsideDefault()">{{ 'web.less' | translate }}</span>
            </a>
        </nav>
    </aside>
</div>
