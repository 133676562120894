import { Component, OnDestroy, OnInit } from '@angular/core';
import { GolfService } from '@services/golf.service';
import { Subscription } from 'rxjs';
import $ from 'src/app/shared/jquery';
import { GolfParticipant, GolfRound, GolfTournament } from '@interfaces/golf.interface';

@Component({
    selector: 'app-golf-participant-tournament',
    templateUrl: './golf-participant-tournament.component.html',
})
export class GolfParticipantTournamentComponent implements OnInit, OnDestroy {
    public golfParticipantData: GolfParticipant;

    public golfTournament: GolfTournament;

    public golfRounds: GolfRound[];
    public participant: GolfParticipant;

    private golfSubscription$: Subscription;

    public golfRoundsSum = {
        birdies: 0,
        bogeys: 0,
        double_bogeys: 0,
        eagles: 0,
        holes_in_one: 0,
        other_scores: 0,
        pars: 0,
        score: 0,
        strokes: 0,
        thru: 0,
    };

    public constructor(public golfService: GolfService) {

    }

    public ngOnDestroy(): void {
        this.golfSubscription$.unsubscribe();
    }

    public ngOnInit(): void {
        this.golfSubscription$ = this.golfService.golfParticipantOpener$.subscribe(async (data) => {
            this.golfTournament = data.golfTournament;
            this.golfParticipantData = data.participant;

            this.golfRoundsSum = {
                birdies: 0,
                bogeys: 0,
                double_bogeys: 0,
                eagles: 0,
                holes_in_one: 0,
                other_scores: 0,
                pars: 0,
                score: 0,
                strokes: 0,
                thru: 0,
            };

            if (data.participant) {
                for (let i = 0; i < data.participant.rounds.length; i += 1) {
                    this.golfRoundsSum.birdies += data.participant.rounds[i].birdies;
                    this.golfRoundsSum.bogeys += data.participant.rounds[i].bogeys;
                    this.golfRoundsSum.double_bogeys += data.participant.rounds[i].double_bogeys;
                    this.golfRoundsSum.eagles += data.participant.rounds[i].eagles;
                    this.golfRoundsSum.holes_in_one += data.participant.rounds[i].holes_in_one;
                    this.golfRoundsSum.other_scores += data.participant.rounds[i].other_scores;
                    this.golfRoundsSum.pars += data.participant.rounds[i].pars;
                    this.golfRoundsSum.score += data.participant.rounds[i].score;
                    this.golfRoundsSum.strokes += data.participant.rounds[i].strokes;
                    this.golfRoundsSum.thru += data.participant.rounds[i].thru;
                }
                const $golfRounds = await this.golfService.getGolfRounds(data.golfTournament.id,
                    data.participant.id, Number(this.golfTournament.playoff));
                $golfRounds.subscribe((data: {rounds:GolfRound[], participant: GolfParticipant}) => {
                    this.golfRounds = data.rounds;
                    this.participant = data.participant;
                    const timeout = setTimeout((): void => {
                        $('[data-toggle="tooltip"]').tooltip();
                        clearTimeout(timeout);
                    }, 1000);
                });
            }
        });
    }

    public close(): void {
        this.golfService.golfParticipantOpener$.next({ golfTournament: this.golfTournament, participant: null });
    }
}
