<ng-container *ngIf="golfParticipantData">
    <div class="golf-aside">
        <div class="match-info-head text-white mb-0 bg-dark p-2 d-flex">
            <div class="mr-auto">
                {{ golfTournament.name }}
            </div>
            <div>
                <a class="text-reset app-cursor" (click)="close()">close</a>
            </div>
        </div>
        <div class="mb-3 px-2 pb-4 bg-white">
            <div class="d-flex flex-row align-items-center mb-3 pt-3 border-top border-light2 position-relative">
                <img
                    *ngIf="participant?.image"
                    src="{{ participant.image }}"
                    alt="{{ golfParticipantData.name }}"
                    class="mr-2"
                    height="50"
                />
                <img
                    *ngIf="!participant?.image && golfParticipantData.iso"
                    src="/img/flags/1x1/{{ golfParticipantData.iso | lowercase }}.svg"
                    alt="{{ golfParticipantData.iso }}"
                    class="rounded-circle mr-2"
                    height="50"
                />
                <img
                    *ngIf="!participant?.image && !golfParticipantData.iso"
                    src="/img/default-avatar.png"
                    alt=""
                    class="rounded-circle mr-2"
                    height="50"
                />
                <div>
                    <h5 class="font-weight-bold d-flex align-items-center flex-nowrap mb-1 app-text">
                        <a
                            [routerLink]="['/page/golf/participant/golf-62/', golfParticipantData.id]"
                            class="text-reset"
                            >{{ golfParticipantData.name }}</a
                        >
                    </h5>
                </div>
            </div>

            <div class="table-responsive">
                <div class="match-info__table small pb-2 text-center">
                    <div
                        *ngIf="golfParticipantData.money"
                        class="match-info__table__row app-data-row-data player-record"
                    >
                        <div class="match-info__table__cell text-left font-weight-bold border-top">
                            {{ 'web.money' | translate }}:
                        </div>
                        <div class="match-info__table__cell text-left font-weight-bold border-top">
                            {{ golfParticipantData.money | number }} {{ golfTournament.currency }}
                        </div>
                    </div>
                    <div
                        *ngIf="golfParticipantData.points"
                        class="match-info__table__row app-data-row-data player-record"
                    >
                        <div class="match-info__table__cell text-left font-weight-bold border-top">
                            {{ 'web.points' | translate }}:
                        </div>
                        <div class="match-info__table__cell text-left font-weight-bold border-top">
                            {{ golfParticipantData.points }}
                        </div>
                    </div>
                    <div
                        *ngIf="golfParticipantData?.position"
                        class="match-info__table__row app-data-row-data player-record"
                    >
                        <div class="match-info__table__cell text-left font-weight-bold border-top">
                            {{ 'web.tournament-position' | translate }}:
                        </div>
                        <div class="match-info__table__cell text-left font-weight-bold border-top">
                            {{ golfParticipantData.position }}
                        </div>
                    </div>
                    <div *ngIf="participant?.ranking" class="match-info__table__row app-data-row-data player-record">
                        <div class="match-info__table__cell text-left font-weight-bold border-top">
                            {{ 'web.ranking' | translate }}:
                        </div>
                        <div class="match-info__table__cell text-left font-weight-bold border-top">
                            {{ participant.ranking.rank }} ({{ participant.ranking.year }})
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table table-sm stage-table golf-table">
                    <thead>
                        <tr class="bg-light2">
                            <td class="small align-middle"></td>
                            <ng-container *ngFor="let round of golfParticipantData.rounds">
                                <td class="small align-middle">{{ round.sequence }}</td>
                            </ng-container>
                            <td class="small align-middle">{{ 'web.sum' | translate }}</td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>Eagles</strong>
                            </td>
                            <td *ngFor="let round of golfParticipantData.rounds">
                                {{ round.eagles }}
                            </td>
                            <td>{{ golfRoundsSum?.eagles }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>Birdies</strong>
                            </td>
                            <td *ngFor="let round of golfParticipantData.rounds">
                                {{ round.birdies }}
                            </td>
                            <td>{{ golfRoundsSum?.birdies }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>Pars</strong>
                            </td>
                            <td *ngFor="let round of golfParticipantData.rounds">
                                {{ round.pars }}
                            </td>
                            <td>{{ golfRoundsSum?.pars }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>Bogeys</strong>
                            </td>
                            <td *ngFor="let round of golfParticipantData.rounds">
                                {{ round.bogeys }}
                            </td>
                            <td>{{ golfRoundsSum?.bogeys }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>Double Bogeys</strong>
                            </td>
                            <td *ngFor="let round of golfParticipantData.rounds">
                                {{ round.double_bogeys }}
                            </td>
                            <td>{{ golfRoundsSum?.double_bogeys }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>Other Scores</strong>
                            </td>
                            <td *ngFor="let round of golfParticipantData.rounds">
                                {{ round.other_scores }}
                            </td>
                            <td>{{ golfRoundsSum?.other_scores }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>Strokes</strong>
                            </td>
                            <td *ngFor="let round of golfParticipantData.rounds">
                                {{ round.strokes }}
                            </td>
                            <td>{{ golfRoundsSum?.strokes }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>Thru</strong>
                            </td>
                            <td *ngFor="let round of golfParticipantData.rounds">
                                {{ round.thru }}
                            </td>
                            <td>{{ golfRoundsSum?.thru }}</td>
                        </tr>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>Holes In One</strong>
                            </td>
                            <td *ngFor="let round of golfParticipantData.rounds">
                                {{ round.holes_in_one }}
                            </td>
                            <td>{{ golfRoundsSum?.holes_in_one }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive" *ngFor="let round of golfRounds">
                <strong>
                    <ng-container *ngIf="round.playoff">PLAY OFF - </ng-container>
                    {{ 'web.round' | translate | titlecase }} {{ round.round }}
                </strong>
                <table class="table table-sm stage-table golf-table">
                    <thead>
                        <tr class="bg-light2">
                            <td class="small text-nowrap text-left">{{ 'web.hole' | translate | titlecase }}</td>
                            <ng-container *ngFor="let score of round.scores">
                                <td class="small align-middle">
                                    <div
                                        class="bg-golf"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        [title]="('web.yardage' | translate) + ': ' + score.yardage"
                                    >
                                        {{ score.number }}
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>PAR</strong>
                            </td>
                            <td *ngFor="let score of round.scores">
                                <div class="bg-golf">{{ score.par }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-nowrap text-left">
                                <strong>{{ golfParticipantData.name }}</strong>
                            </td>
                            <td *ngFor="let score of round.scores">
                                <div
                                    [class.--dark-text]="score.strokes !== 0"
                                    [class]="
                                        '--dark-text bg-golf bg-golf bg-golf-' +
                                        (score.strokes !== 0 ? score.strokes - score.par : '')
                                    "
                                >
                                    {{ score.strokes }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="golf-legend mt-3 d-flex justify-content-start flex-wrap">
                <div class="p-1"><span class="bg-golf bg-golf--3">&nbsp;</span> Double-Eagle</div>
                <div class="p-1"><span class="bg-golf bg-golf--2">&nbsp;</span> Eagle</div>
                <div class="p-1"><span class="bg-golf bg-golf--1">&nbsp;</span> Birdie</div>
                <div class="p-1"><span class="bg-golf bg-golf-0">&nbsp;</span> Par</div>
                <div class="p-1"><span class="bg-golf bg-golf-1">&nbsp;</span> Bogey</div>
                <div class="p-1"><span class="bg-golf bg-golf-2">&nbsp;</span> Double-Bogey</div>
                <div class="p-1"><span class="bg-golf bg-golf-3">&nbsp;</span> 3+ Bogey</div>
            </div>
        </div>
    </div>
</ng-container>
