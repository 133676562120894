<div>
    <div class="d-flex table-scrollable">
        <div style="width: 100%; overflow: auto">
            <table data-cy="app-table-right-data" class="table-scrollable__data__table">
                <colgroup>
                    <col [class.--active-row]="activeRow === 1" />
                    <col [class.--active-row]="activeRow === 2" />
                    <col [class.--active-row]="activeRow === 3" />
                    <col [class.--active-row]="activeRow === 4" />
                    <col [class.--active-row]="activeRow === 5" />
                    <col [class.--active-row]="activeRow === 6" />
                    <col [class.--active-row]="activeRow === 7" />
                    <col [class.--active-row]="activeRow === 8" />
                    <col [class.--active-row]="activeRow === 9" />
                    <col [class.--active-row]="activeRow === 10" />
                    <col [class.--active-row]="activeRow === 11" />
                    <col [class.--active-row]="activeRow === 12" />
                    <col [class.--active-row]="activeRow === 13" />
                    <col [class.--active-row]="activeRow === 14" />
                    <col [class.--active-row]="activeRow === 15" />
                    <col [class.--active-row]="activeRow === 16" />
                    <col [class.--active-row]="activeRow === 17" />
                </colgroup>
                <thead>
                    <tr>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor"
                            title=""
                            scope="col"
                        >
                            {{ 'web.season' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.world_rank' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.earnings' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.first_place' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.second_place' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.third_place' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.top_10' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.top_25' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.scoring_avg' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.drive_avg' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.total_driving' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.drive_acc' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.gir_pct' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.sand_saves_pct' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.strokes_gained' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.strokes_gained_total' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.scrambling_pct' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-scrollable__table-row" *ngFor="let row of data; trackBy: trackByYear">
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td text-bold"
                            (mouseenter)="activeRow = 1"
                            (mouseleave)="activeRow = 0"
                        >
                            {{ row.year }}
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 2"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.world_rank; else elseTmp">
                                {{ row.world_rank }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 3"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.earnings; else elseTmp">
                                {{ row.earnings | number }} USD
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 4"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.first_place; else elseTmp">
                                {{ row.first_place }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 5"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.second_place; else elseTmp">
                                {{ row.second_place }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 6"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.third_place; else elseTmp">
                                {{ row.third_place }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 7"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.top_10; else elseTmp">
                                {{ row.top_10 }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 8"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.top_25; else elseTmp">
                                {{ row.top_25 }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 9"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.scoring_avg; else elseTmp">
                                {{ row.scoring_avg }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 10"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.drive_avg; else elseTmp">
                                {{ row.drive_avg }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 11"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.total_driving; else elseTmp">
                                {{ row.total_driving }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 12"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.drive_acc; else elseTmp">
                                {{ row.drive_acc }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 13"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.gir_pct; else elseTmp"> {{ row.gir_pct }}% </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 14"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.sand_saves_pct; else elseTmp">
                                {{ row.sand_saves_pct }}%
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 15"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.strokes_gained; else elseTmp">
                                {{ row.strokes_gained }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 16"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.strokes_gained_total; else elseTmp">
                                {{ row.strokes_gained_total }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 17"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.scrambling_pct; else elseTmp">
                                {{ row.scrambling_pct }}%
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
