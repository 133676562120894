<ng-container *ngFor="let round of data; trackBy: trackByIndex">

    <div *ngIf="round.courses.length > 0 && round.courses[0].holes.length > 0" class="mb-4">

        <h3 class="ml-2">{{ round.number }}. {{ 'web.round' | translate }}</h3>

        <div class="d-flex table-scrollable">
            <div class="table-scrollable__fixed">
                <div class="table-scrollable__fixed-shadow"></div>
                <table class="table-scrollable__fixed__table stage-table golf-table-list">
                    <thead>
                    <tr class="bg-light">
                        <th class="table-scrollable__table-th font-weight-normal pl-1">&nbsp;<br/>{{ 'web.hole' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        class="table-scrollable__table-row app-cursor"
                        *ngFor="let hole of round.courses[0].holes; trackBy: trackByIndex"
                    >
                        <th class="table-scrollable__table-td pl-2">
                            {{ hole.number }}
                        </th>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-scrollable__data flex-grow-1" tableScroll>
                <table class="table-scrollable__data__table stage-table golf-table-list">
                    <thead>
                    <tr class="bg-light">

                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">PAR</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">{{ 'web.strokes' | translate }}</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">{{ 'web.golf-players' | translate }}</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">Eagles</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">Birdies</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">PARs</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">Bogeys</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">Double<br/>Bogeys</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">{{ 'web.holes-in-one' | translate }}</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">{{ 'web.other-scores' | translate }}</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">{{ 'web.strokes-avg' | translate }}</th>
                        <th class="table-scrollable__table-th table-scrollable__data__table-th vab">{{ 'web.avg-diff' | translate }}</th>

                    </tr>
                    </thead>
                    <tbody>
                    <ng-container>
                        <tr
                            class="table-scrollable__table-row app-cursor"
                            *ngFor="let hole of round.courses[0].holes; trackBy: trackByIndex"
                        >
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.par }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.strokes }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.players }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.eagles }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.birdies }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.pars }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.bogeys }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.double_bogeys }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.holes_in_one }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.other_scores }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.strokes_avg }}</td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">{{ hole.avg_diff }}</td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

</ng-container>
