/**
 *  Match list page
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription, timer, of, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import $ from 'src/app/shared/jquery';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { LiveDatePipe } from 'src/app/shared/pipes/live-datetime.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import SPORT_CONFIG from 'src/app/config/sport.config';
import { switchMap } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';

import { environment } from '@environments/environment';

import { PageService } from '@services/page.service';
import { TimeService } from '@services/time.service';
import { CategoryInterface } from '@interfaces/category.interface';
import { MatchData } from '@interfaces/match-data.interface';
import { UtilsService } from '@services/utils.service';
import { ClientService } from '@services/client.service';
import { FavoriteService } from '@services/favorite.service';
import { LangService } from '@services/lang.service';
import { InfoService } from '@services/info.service';
import { GolfService } from '@services/golf.service';
import { GolfTournament } from '@interfaces/golf.interface';

import { BaseComponent } from '../../base.component';

declare const moment: any;

@Component({
    selector: 'app-golf-list',
    templateUrl: './golf-list.component.html',
})
export class GolfListComponent extends PageMixin(BaseComponent) implements OnInit, OnDestroy {
    public sportId: number;

    public sportName: string;

    public data: GolfTournament[];

    /**
     * Data for each category
     * @param protectedpage [description]
     * @param router        [description]
     * @param matchList     [description]
     */
    public categoryData: { [prop: number]: MatchData[] };

    public categoryGroupData: { [prop: number]: CategoryInterface[] };

    private openCategories: string[] = [];

    public stageId: number | null = null;

    public stageParticipantStageId: number;

    public stageParticipantParticipantId: number;

    public golfTournament: GolfTournament[];

    public tracker: boolean = false;

    public detailCaption: string = '';

    public date: string | null = null;

    public paramDate: string | any | null = null;

    public alphaAvailLetters: string[] = [];

    public alphaFilter?: { star: boolean; alpha: string[] };

    private subscriber: Subscription;

    private subscriber2: Subscription;

    private subscriberReload: Subscription;

    private subscriberTab: Subscription;

    private from: any;

    private to: any;

    public type: string;

    private subTournamets: number[] = [];

    public slice: number = 1000;

    public categoryGroup: CategoryInterface[] = [];

    public showList = true;

    /**
     * Switch group category on/off
     */
    public isGroupCategory: boolean = false;

    /**
     * number of sport aside menu
     */
    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    /**
     * First data load
     */
    public first: boolean;

    public firstDefault: boolean;

    private firstInterval: number;

    private scrollNum: number = 0;

    private firstScroll: boolean = false;

    private openAll: boolean | null = null;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        private golfService: GolfService,
        private favorite: FavoriteService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public deviceService: DeviceDetectorService,
        private storage: StorageService,
    ) {
        super(page, title, meta, route, info);
    }

    @HostListener('window:scroll', [])
    private onWindowScroll(): void {
        const val = window.scrollY;
        this.storage.set('APP_LAST_SCROLL', val);
    }

    public ngOnInit(): void {
        super.ngOnInit();


        this.router.paramMap.subscribe((params): void => {
            $('[data-toggle="tooltip"]').tooltip('hide');
            this.data = [];
            this.first = true;

            if (params.has('sport')) {
                [this.sportId, this.sportName] = UtilsService.parseSportUrl(params.get('sport')!);
                const date = params.has('date')
                    ? TimeService.getLocalTime(params.get('date')!, TimeService.dateFormat)
                    : moment();
                this.paramDate = params.has('date') ? params.get('date') : null;
                this.from = TimeService.startLocal(moment(date));
                this.to = TimeService.endLocal(moment(date));
                // this.type = params.has("type") ? params.get("type") : "all";

                // this.loader();
            } else {
                this.error();
            }

            this.lang.getLang().then((): Promise<any> => this.createTitle());

            this.changeTab('all');
        });

        this.favorite.onToggleTournament.subscribe((): void => {
            this.load(true);
        });

        this.golfService.golfParticipantOpener$.subscribe((data) => {
            this.golfTournament = data.golfTournament;

            if (this.deviceService.isMobile() && data.participant) {
                this.showList = false;
            } else {
                this.showList = true;
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroyAll();
        $('[data-toggle="tooltip"]').tooltip('hide');
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_sport_detail').subscribe((): void => {
            const sport = this.translate.instant(`sport.${this.sportName}`);
            const trans = this.translate.instant('web.title_sport_detail');
            const desc = this.translate.instant('web.description_sport_detail');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${sport} - ${trans} | ${client}`,
                    description: `${sport} ${desc}`,
                },
                true,
            );
        });
    }

    /**
     * Load data
     */
    private loader(): void {
        this.destroyAll();

        this.subscriberReload = timer(0, environment.golfListReloadTime)
            .pipe(switchMap((t): any => of(t)))
            .subscribe((): Promise<void> => this.load());
    }

    /**
     * Toggle aside menu
     * @return {void}
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Destroy all subscribers
     * @return {void}
     */
    private destroyAll(): void {
        this.data = [];
        clearInterval(this.firstInterval);
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }

        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriber2 instanceof Subscription) {
            this.subscriber2.unsubscribe();
        }
    }

    private destroyPart(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriber2 instanceof Subscription) {
            this.subscriber2.unsubscribe();
        }
    }

    public trackByFn(index: number, item: Record<string, any>): number | null {
        if (_.isUndefined(item)) {
            return null;
        }
        return item.id;
    }

    /**
     * Date change event
     * @event MatchListComponent#dateChange
     * @param  date [description]
     * @return      [description]
     */
    public dateChange(date: any): void {
        this.date = date.format(TimeService.dateFormat);
        this.route.navigate(['page', 'golf', 'golf-list', `${this.sportName}-${this.sportId}`, { date: this.date }]);
    }

    public changeTab(tab: string = 'all'): void {
        this.type = tab;
        this.golfService.lastTab = tab;
        this.first = true;
        this.firstDefault = false;
        this.alphaFilter = undefined;
        this.reset();
        this.data = [];
        this.categoryData = {};
        this.categoryGroupData = {};
        this.alphaAvailLetters = [];
        this.loader();
        this.golfService.alphaReset.emit(true);

        if (this.isGroupCategory) {
            if (tab === 'live') {
                this.categoryGroup.forEach((v): void => {
                    v.isopen = true;
                });
            } else {
                this.categoryGroup.forEach((v): void => {
                    v.isopen = false;
                });
            }
        }
    }

    /**
     * Format UTC datetime to date dd.mm.YYYY
     * @param  {string} date
     * @return {string}
     */
    public setDate(date: string): string {
        const obj = new LiveDatePipe();
        return obj.transform(date, 'datedb');
    }

    /**
     * Check if data for suboturnamen texists
     * @param  {number} subTournamentId
     * @return    {MatchData[]}
     */
    public getData(subTournamentId: number): MatchData[] {
        return (this.categoryData && this.categoryData[subTournamentId]) || [];
    }

    /**
     * Event fired when some match is selected/clicked
     * @event MatchListComponent#loadMatch
     * @param  stage
     * @param  {boolean} updateCaption
     * @return {void}
     */
    public loadStage(stage: any, updateCaption: boolean = true): void {
        if (updateCaption) {
            this.detailCaption = '';
        }
        if (this.stageId != null && this.stageId === stage.stageId) {
            return;
        }

        // this.stageId = stage.stageId;
        this.tracker = false;
    }

    /**
     *
     * @param data
     */
    public loadStageParticipant(data: any): void {
        this.stageParticipantStageId = data.stageId;
        this.stageParticipantParticipantId = data.participantId;
    }

    /**
     * Open default match on first load
     * @return {void}
     */
    private openDefault(): void {
        if (_.isUndefined(this.data[0])) {
            this.stageId = null;
        } else {
            this.loadStage({ stageId: this.data });
        }
    }

    public showMoreResults(): void {
        this.slice += environment.matchListMobileLimit;
    }

    /**
     * Chekc if there is more results to show
     * @return {boolean}
     */
    public isMoreResults(): boolean {
        return this.data.length > this.slice;
    }

    private filtrAlpha(data: any): Promise<string[]> {
        return new Promise((resolve): void => {
            const ret: string[] = [];
            if (_.isArray(data)) {
                data.forEach((v): void => {
                    if (SPORT_CONFIG.isSet(this.sportName)) {
                        if (v.sub_tournament_name !== null) {
                            ret.push(v.sub_tournament_name.charAt(0).toLowerCase());
                        }
                    } else if (v.category_name !== null) {
                        ret.push(v.category_name.charAt(0).toLowerCase());
                    }
                });
            }

            resolve(ret);
        });
    }

    private isCategoryOpen(subId: number): boolean {
        const openCategories = this.storage.get<any[]>(SPORT_CONFIG.categoryIndex) || [];

        const index = openCategories.findIndex((val): boolean => val === subId);

        return index !== -1;
    }

    /**
     * Manage which categories will be open in which situation
     */
    private handleOpenCategories(c: CategoryInterface, key: number): void {
        if (!this.deviceService.isMobile() && this.type !== 'live' && key < 4) {
            c.isopen = true;
        } else if (!this.deviceService.isMobile() && this.type === 'live' && key < 8) {
            c.isopen = true;
        } else if (this.deviceService.isMobile() && this.type !== 'live' && key < 1) {
            c.isopen = true;
        } else if (this.deviceService.isMobile() && this.type === 'live' && key < 4) {
            c.isopen = true;
        } else if (this.openAll) {
            c.isopen = true;
        } else {
            c.isopen = false;
        }

        if (this.isCategoryOpen(c.sub_tournament_id)) {
            c.isopen = true;
        } else if (this.favorite.existsTournament(this.sportId, c.tournament_id)) {
            c.isopen = true;
        }
    }

    public onOpenChange(event: any): void {
        this.openAll = event;

        this.load(true);
    }

    /**
     *
     * @param category
     * @private
     */
    private getCategoryGroup(category: CategoryInterface[]): CategoryInterface[] {
        const ret: any[] = [];
        category.forEach((cat): void => {
            const find = ret.find((val): boolean => val.category_id === cat.category_id);

            if (!find) {
                const found = this.categoryGroup.find((val2): boolean => val2.category_id === cat.category_id);

                const saved = this.storage.get<number[]>('APP_GROUP_MATCHES');
                const found2 = saved ? saved.includes(cat.category_id) : false;
                if (this.first && found2) {
                    cat.isopen = true;
                } else if (found) {
                    cat.isopen = found.isopen;
                } else {
                    cat.isopen = false;
                }
                ret.push(cat);
            }
        });
        return JSON.parse(JSON.stringify(ret));
    }

    /**
     * Load tournament categories
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected async load(updateAll: boolean = false,
        data: Observable<CategoryInterface[]> | null = null): Promise<any> {
        try {
            data = await this.golfService.getCategories(this.sportId, this.from, this.to);

            this.subscriber = data.subscribe(
                (val): any => {
                    // @ts-ignore
                    this.data = val;

                    if (this.first) {
                        this.openDefault();
                    }
                    this.first = false;

                    this.finished(true);
                },
                (): void => {
                    if (this.first) {
                        this.error(true);
                    }
                },
            );
        } catch (e) {
            if (this.first) {
                this.error(true);
            }
        }
    }

    private openAtLastOne(): Promise<void | false> {
        return new Promise(
            (resolve): any => {
                if (this.data.length === 0) {
                    resolve(false);
                }
                resolve();
            },
        );
    }

    public openCategory(categoryId: string): void {
        window.requestAnimationFrame((): void => {
            this.golfService.toggleOpenCategory(categoryId);
        });
    }

    public isCategoryClose(categoryId: number): boolean {
        return this.golfService.isCategoryClose(categoryId);
    }
}
