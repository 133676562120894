import { Component, Input } from '@angular/core';
import { BaseComponent } from '@components/base.component';
import { GolfBalance } from '@interfaces/golf.interface';


@Component({
    selector: 'app-golf-balance',
    templateUrl: './golf-balance.component.html',
})
export class GolfBalanceComponent extends BaseComponent {
    public activeRow = 0;

    @Input()
    public data: GolfBalance[];

    public constructor() {
        super();
    }

    public trackByYear(index: number, val: GolfBalance): number {
        return val.year;
    }
}
