import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BaseComponent } from '@components/base.component';
import { GolfTournament } from '@interfaces/golf.interface';


@Component({
    selector: 'app-golf',
    templateUrl: './golf.component.html',
})
export class GolfComponent extends BaseComponent {
    @Input()
    public golfTournament: GolfTournament;

    @Input()
    public categoryName: string;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    @Input()
    public showDetailBtn = true;

    @Output()
    public onSelectStage: EventEmitter<Record<string, any>> = new EventEmitter();

    public constructor(
        public deviceService: DeviceDetectorService,
    ) {
        super();
    }
}
