<div class="container bg-light position-relative tournament-page">
    <ng-container *ngIf="loaded && (data || loadedError); else elseBlock">
        <div *ngIf="loadedError" class="pb-6">
            <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                <div class="text-center pt-7">
                    <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-' + sportName" /></svg>
                    <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                    <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="row bg-white p-0" *ngIf="!loadedError">
            <!-- Col Left -->
            <div *ngIf="showList" class="col-lg-6 bg-white pl-0 pr-0">
                <div class="pl-5 pt-5 pr-5 pr-lg-0 pb-3">
                    <!--Progress bar + logo-->
                    <div class="row no-gutters">
                        <div class="col-md-8">
                            <div class="media">
                                <div class="img-team img-team--lg mr-3">
                                    <img
                                        src="{{
                                            cdn + '/img/flags/1x1/' + (tournament.country_iso | lowercase) + '.svg'
                                        }}"
                                        width="68"
                                        class="img-fluid mr-2"
                                        alt="{{ tournament.country_iso3 }}"
                                    />
                                    <!--                                    <ng-template #adminIcon>-->
                                    <!--                                        <img-->
                                    <!--                                            [src]="cdn + data?.image"-->
                                    <!--                                            alt="{{ data?.tournament_name }}"-->
                                    <!--                                            width="68"-->
                                    <!--                                            class="img-fluid mr-2"-->
                                    <!--                                        />-->
                                    <!--                                    </ng-template>-->
                                </div>
                                <div class="media-body">
                                    <div class="d-flex icon-wrapper justify-content-start flex-wrap">
                                        <h1 class="text-uppercase mb-2">{{ tournament.name }}</h1>
                                        <div class="break-md"></div>
                                    </div>
                                    <div *ngIf="tournament.venue" class="mb-2 h5">
                                        <span class="font-weight-bold mr-1">{{ tournament.venue.name }}</span>
                                        ({{ tournament.venue.city }},
                                        <ng-container *ngIf="tournament.venue.state"
                                            >{{ tournament.venue.state }},</ng-container
                                        >
                                        {{ tournament.venue.country }})
                                    </div>
                                    <!--                                    <div class="col-12 col-sm-9 pt-2 pb-2 pt-md-3 pl-md-1 pr-md-3">-->
                                    <!--                                        <div class="progress">-->
                                    <!--                                            <mat-progress-bar-->
                                    <!--                                                mode="determinate"-->
                                    <!--                                                class="progress-bar"-->
                                    <!--                                                [value]="actualSeasonProgress"-->
                                    <!--                                            ></mat-progress-bar>-->
                                    <!--                                        </div>-->
                                    <!--                                        <div class="row mt-2 small" *ngIf="progress">-->
                                    <!--                                            <div class="col">-->
                                    <!--                                                {{ progress?.start | livDate : 'localDate' }}-->
                                    <!--                                            </div>-->
                                    <!--                                            <div class="col text-right">-->
                                    <!--                                                {{ progress?.end | livDate : 'localDate' }}-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                </div>
                            </div>
                        </div>
                        <!--Tournament name +  filters-->
                        <div class="col-md-4 text-md-right mt-2 mt-md-auto">
                            <div
                                class="d-flex flex-column-reverse align-items-stretch flex-md-column align-items-end mr-sm-3"
                            ></div>
                        </div>
                    </div>
                </div>
                <!--End Tournament name +  filters-->
                <div class="nav-wrapper app-mob-nav-wrapper">
                    <!-- <div class="app-mob-nav-shadow"></div> -->
                    <ul
                        class="nav nav-tabs nav-tabs--shadows font-weight-bold pt-3"
                        id="tabs-left"
                        role="tablist"
                        scrollEnd
                    >
                        <li class="nav-item">
                            <a
                                class="nav-link px-3 px-md-4 active"
                                [class.active]="activeTab == 1"
                                id="tab1"
                                data-toggle="tab"
                                href="#overview"
                                role="tab"
                                (click)="activeTabFce(1)"
                                aria-controls="tab1-content"
                                aria-selected="true"
                                >{{ 'web.overview' | translate | transinit }}</a
                            >
                        </li>

                        <li class="nav-item" *ngIf="tournament.tee_times.length > 0">
                            <a
                                class="nav-link px-3 px-md-4"
                                [class.active]="activeTab == 4"
                                id="tab4"
                                data-toggle="tab"
                                href="#tea-times"
                                role="tab"
                                (click)="activeTabFce(4)"
                                aria-controls="tab4-content"
                                aria-selected="false"
                                >{{ 'web.tee_times' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="tournament.hole_statistics.length > 0">
                            <a
                                class="nav-link px-3 px-md-4 app-cursor"
                                [class.active]="activeTab == 5"
                                id="tab5"
                                data-toggle="tab"
                                href="#hole-statistics"
                                (click)="activeTabFce(5)"
                                role="tab"
                                aria-controls="tab5-content"
                                aria-selected="false"
                                >{{ 'web.hole_statistics' | translate | transinit }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="tab-content bg-white px-0 px-lg-3 py-3 position-relative">
                    <div
                        class="tab-pane active"
                        [class.active]="activeTab == 1"
                        *ngIf="activeTab == 1"
                        id="overview"
                        role="tabpanel"
                        aria-labelledby="tab1"
                    >
                        <ng-container *ngIf="data.length > 0">
                            <div class="table-sport mx-sm-0 mb-3">
                                <h6
                                    class="table-sport__head bg-dark text-white"
                                    data-toggle="collapse"
                                    [class.collapsed]="false"
                                    [attr.data-target]="'#collapse-category-1'"
                                    role="button"
                                    [attr.aria-expanded]="true"
                                    [attr.aria-controls]="'collapse-category-1'"
                                >
                                    <img src="" alt="" width="20" class="mr-1" />
                                    <span class="table-sport__head__text">PGA Tour</span>
                                </h6>

                                <div
                                    class="table-sport__content collapse"
                                    [class.show]="true"
                                    [attr.id]="'collapse-category-1'"
                                >
                                    <app-golf
                                        *ngFor="let golfTournament of data; trackBy: trackById"
                                        [golfTournament]="golfTournament"
                                        [categoryName]="golfTournament.name"
                                        [sportId]="sportId"
                                        [sportName]="sportName"
                                        [showDetailBtn]="false"
                                    ></app-golf>
                                </div>
                            </div>

                            <ng-container *ngIf="!loadedError && data.length == 0">
                                <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                    <div class="text-center pt-7 app-match-no-data">
                                        <svg class="icon icon--64 mb-4">
                                            <use [attr.xlink:href]="'#icon-' + sportName" />
                                        </svg>
                                        <h4 class="font-weight-bold">
                                            {{ 'web.no_tournaments_found' | translate }}
                                        </h4>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 4"
                        *ngIf="activeTab == 4"
                        id="tea-times"
                        role="tabpanel"
                        aria-labelledby="tab4"
                    >
                        <app-golf-tee-times [data]="tournament.tee_times"></app-golf-tee-times>
                    </div>
                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 5"
                        *ngIf="activeTab == 5"
                        id="hole-statistics"
                        role="tabpanel"
                        aria-labelledby="tab5"
                    >
                        <app-golf-hole-statistics [data]="tournament.hole_statistics"></app-golf-hole-statistics>
                    </div>

                    <div
                        *ngIf="client.use('seoCard') && tournament?.golf_tournament_info"
                        class="card shadow border-0 mt-5"
                    >
                        <div class="app-info-card card-body p-0 p-md-5">
                            <div class="media">
                                <svg class="icon icon--32 mr-3">
                                    <use href="#icon-bulp-2" />
                                </svg>
                                <div class="media-body mt-2">
                                    <h6 class="font-weight-bold text-uppercase">{{ tournament?.name }}</h6>
                                    <div [innerHTML]="tournament?.golf_tournament_info | markdown"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- / Col Left -->
            <!-- Col Right -->
            <div class="col-lg-6 p-0 p-lg-3">
                <div [class.sticky-parent]="stickyDetail">
                    <app-golf-participant-tournament></app-golf-participant-tournament>

                    <app-ranking
                        *ngIf="!deviceService.isMobile()"
                        [sportName]="sportName"
                        [sportId]="sportId"
                    ></app-ranking>
                </div>
            </div>
            <!-- / Col Right -->
        </div>

        <aside class="nav-sports d-none d-xl-block" *ngIf="client.use('sportQuickNav')">
            <nav class="nav flex-column bg-light">
                <app-sport-navigation type="aside" [limit]="asideLimit" [sportId]="sportId"></app-sport-navigation>
                <a
                    class="nav-link nav-link--more text-uppercase text-reset"
                    [class.nav-link--more]="isAsideDefault()"
                    [class.nav-link--less]="!isAsideDefault()"
                    (click)="toggleAssideLimit()"
                >
                    <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                    <span *ngIf="!isAsideDefault()">{{ 'web.less' | translate }}</span>
                </a>
            </nav>
        </aside>
        <aside [class.d-xl-block]="client.use('sportQuickNav')" class="nav-sports nav-sports--extra d-none">
            <nav class="nav flex-column nav-side-menu bg-light">
                <app-side-menu-navigation [sportId]="sportId" [sportName]="sportName"></app-side-menu-navigation>
            </nav>
        </aside>
    </ng-container>
    <ng-template #elseBlock>
        <div class="row">
            <!-- Col Left -->
            <div class="col-lg-6 bg-white pl-0 pr-0">
                <app-placeholder-tournament></app-placeholder-tournament>
            </div>

            <div class="col-lg-6 p-3 d-none d-lg-block">
                <app-placeholder-detail></app-placeholder-detail>
            </div>
        </div>
    </ng-template>
</div>
