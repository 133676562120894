import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { of, Subscription, merge, Observable } from 'rxjs';
import * as _ from 'underscore';
import $ from 'jquery';
import { Store } from '@ngxs/store';
import { UpdateMarkets } from 'src/app/store/actions/bets.action';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { LangService } from '@services/lang.service';

// import { TournamentService } from '@services/tournament.service';
import { UtilsService } from '@services/utils.service';

@Component({
    selector: 'app-page-golf',
    templateUrl: './page-golf.component.html',
})
export class PageGolfComponent implements OnInit, OnDestroy {
    public sportId: number | null = null;

    public sportName: string;

    public categoryId: number | null = null;

    public date: string | null = null;

    private subscription: Subscription;

    public constructor(
        private router: ActivatedRoute,
        private route: Router,
        private store: Store,
        // private tournament: TournamentService,
        public deviceService: DeviceDetectorService,
        // private lang: LangService,
    ) {}

    public ngOnInit(): void {
        const one = this.route.events.pipe(
            filter((event): boolean => event instanceof NavigationEnd),
            switchMap(
                (): Observable<any> => (this.router.firstChild && this.router.firstChild.params) ||
                    of({}),
            ),
            distinctUntilChanged(),
        );

        const two = this.router.firstChild!.paramMap.pipe(
            switchMap((p): Observable<any> => of(p)),
        );

        this.subscription = merge(one, two).subscribe((val: any): void => {
            if (!_.isUndefined(val.get)) {
                if (val.has('sport')) {
                    [this.sportId, this.sportName] = UtilsService.parseSportUrl(
                        val.get('sport'),
                    );
                }
                if (val.has('date')) {
                    this.date = val.get('date');
                }
            } else {
                if (_.has(val, 'sport')) {
                    [this.sportId, this.sportName] = UtilsService.parseSportUrl(
                        val.sport,
                    );
                }
                if (_.has(val, 'date')) {
                    this.date = val.date;
                }
            }
        });


        this.store.dispatch(new UpdateMarkets());

        // TODO workaround push notification translation not working on stage sports
        $('#push-notification').hide();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
