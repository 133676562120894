import { Component, Inject, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { Router } from '@angular/router';

import { BaseComponent } from '@components/base.component';

import { GolfService } from '@services/golf.service';
import { GolfParticipant, GolfTournament } from '@interfaces/golf.interface';
import { SportConfigToken } from '@config/sport.config';
import { trigger } from '@angular/animations';
import { SCORE_ANIMATION_LONG } from '@animations/score.animation';

@Component({
    selector: 'app-golf-table',
    templateUrl: './golf-table.component.html',
    animations: [trigger('score', SCORE_ANIMATION_LONG)],
})
export class GolfTableComponent extends BaseComponent {
    @Input()
    public data: GolfTournament;

    public activeParticipantId = 0;

    public slice;

    // @Input()
    // public valueTypes: any; // stage.valueTypes.OutrightCompetitorValueTypes, stage.valueTypes.OutrightTeamValueTypes

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    public constructor(
        private golfService: GolfService,
        public deviceService: DeviceDetectorService,
        @Inject(SportConfigToken) private sportConfig: Record<string, any>,
        // private router: Router,
    ) {
        super();

        if (!this.slice) {
            this.slice = this.sportConfig.golf.maxInitPlayers;
        }
    }


    get isMore(): boolean {
        return this.data.participants.length <= this.sportConfig.golf.maxInitPlayers ||
        this.slice !== this.sportConfig.golf.maxInitPlayers;
    }

    public openGolfParticipant(golfTournament: GolfTournament, participant: GolfParticipant): void {
        // if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
        //     this.router.navigate([
        //         `/page/golf/event-participant/${this.sportName}-${this.sportId}`,
        //         parentStageId, stageId, participantId,
        //     ]);
        // } else {

        this.activeParticipantId = participant.id;
        this.golfService.golfParticipantOpener$.next({ golfTournament, participant });
        // }
    }
}
