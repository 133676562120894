import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageGolfComponent } from '@pages/page-golf/page-golf.component';
import { GolfListComponent } from '@pages/page-golf/golf-list/golf-list.component';
import { GolfParticipantComponent } from '@pages/page-golf/golf-participant/golf-participant.component';
import { GolfTournamentComponent } from '@pages/page-golf/golf-tournament/golf-tournament.component';


const routes: Routes = [
    {
        path: '',
        component: PageGolfComponent,
        children: [
            {
                path: 'golf-list/:sport',
                component: GolfListComponent,
            },
            {
                path: 'participant/:sport/:id',
                component: GolfParticipantComponent,
            },
            {
                path: 'tournament/:sport/:tournament',
                component: GolfTournamentComponent,
            },
            // {
            //     path: 'event-participant/:sport/:root-golf/:event/:participant',
            //     component: GolfEventParticipantComponent,
            // },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class GolfRoutingModule {}
