/**
 *  Service for match list
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, distinctUntilChanged, tap, map } from 'rxjs/operators';
import * as _ from 'underscore';

import { ParticipantInterface } from '@interfaces/participant.interface';

import { GolfTournament } from '@interfaces/golf.interface';

import { CommonService } from '../shared/common.service';
import { LangService } from '../services/lang.service';

import { CategoryInterface } from '../interfaces/category.interface';

import URL_CONFIG from '../config/url.config';

import { StageCategory } from '../interfaces/stage.interface';

import { UtilsService } from './utils.service';
import { TimeService } from './time.service';
import { FavoriteService } from './favorite.service';


@Injectable({
    providedIn: 'root',
})
export class GolfService {
    public loaded: EventEmitter<number> = new EventEmitter();

    public alphaReset: EventEmitter<boolean> = new EventEmitter();

    public golfParticipantOpener$ = new Subject<{golfTournament: any, participant: any}>();

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _lastTab: string;

    public openStages = [];

    public closeCategories = [];

    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private lang: LangService,
        private favorite: FavoriteService,
    ) {}

    public get lastTab(): string {
        return this._lastTab;
    }

    public set lastTab(tab: string) {
        this._lastTab = tab;
    }

    public toggleOpenStage(stageId: number): void {
        const index = this.openStages.indexOf(stageId as never);
        if (index > -1) {
            delete this.openStages[index];
        } else {
            this.openStages.push(stageId as never);
        }
    }

    public isStageOpen(stageId: number): boolean {
        // Deactivate this functionality
        return this.openStages.includes(stageId as never);
    }

    public closeAllStages(): void {
        this.openStages = [];
    }

    public toggleOpenCategory(categoryId: string): void {
        const index = this.closeCategories.indexOf(categoryId as never);
        if (index > -1) {
            delete this.closeCategories[index];
        } else {
            this.closeCategories.push(categoryId as never);
        }
    }

    public isCategoryClose(categoryId: number): boolean {
        return this.closeCategories.includes(categoryId as never);
    }

    /**
     * Get Stage list categories
     * @return {Promise<Observable<CategoryInterface>>}
     */
    public async getCategories(
        sportId: any,
        from: any,
        to: any,
    ): Promise<Observable<CategoryInterface[]>> {
        const url = UtilsService.replace('sportId', sportId as string, URL_CONFIG.api.getGolfTournamentsByDate);

        return this.lang.getLang().then(
            (iso): Observable<CategoryInterface[]> => {
                const params = new HttpParams()
                    .set('lang', iso)
                    .set('from', TimeService.toUtc(from).format(TimeService.dateTimeFormat))
                    .set('to', TimeService.toUtc(to).format(TimeService.dateTimeFormat));
                const options = params ? { params } : {};
                return this.http.get<CategoryInterface[]>(url, options).pipe(
                    distinctUntilChanged(),
                    switchMap(this.mapCategory(sportId)),
                    catchError(this.common.errorCallback2()), // then handle the error
                    tap(this.common.networkOnline()),
                );
            },
            (): Promise<any> => Promise.reject(new Error()),
        );
    }

    public getGolfRounds(tournamentId: number, participantId: number, playoff: number): Promise<Observable<any>> {
        // eslint-disable-next-line no-restricted-globals
        if (!tournamentId) {
            return Promise.resolve(of(null));
        }
        if (!participantId) {
            return Promise.resolve(of(null));
        }
        return this.lang.getLang().then(
            (iso) => {
                const params = new HttpParams()
                    .set('lang', iso)
                    .set('tournamentId', tournamentId)
                    .set('participantId', participantId)
                    .set('playoff', playoff);
                const options = params ? { params } : {};

                return this.http.get<any>(URL_CONFIG.api.getGolfRounds, options)
                    .pipe(map(responseData => responseData));
            },
            (): Promise<any> => Promise.reject(new Error()),
        );
    }

    public getTournamentDetail(id: any): Promise<Observable<GolfTournament[]>> {
        // eslint-disable-next-line no-restricted-globals
        if (!id || isNaN(id)) {
            return Promise.resolve(of([]));
        }
        return this.lang.getLang().then(
            (iso): Observable<GolfTournament[]> => {
                const url = UtilsService.replace('id', id as string, URL_CONFIG.api.getGolfTournamentDetail);
                const params = new HttpParams().set('lang', iso);
                const options = params ? { params } : {};

                return this.http.get<GolfTournament[]>(url, options)
                    .pipe(map((responseData: GolfTournament[]) => responseData));
            },
            (): Promise<any> => Promise.reject(new Error()),
        );
    }

    // public getTournamentDetail(id: any): Promise<Observable<GolfTournament[]>> {
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!id || isNaN(id)) {
    //     return Promise.resolve(of([]}));
    // }
    // return this.lang.getLang().then(
    //     (iso): Observable<GolfTournament[]> => {
    //         const url = UtilsService.replace('id', id as string, URL_CONFIG.api.getGolfTournamentDetail);
    //         const params = new HttpParams().set('lang', iso);
    //         const options = params ? { params } : {};
    //
    //         return this.http.get<GolfTournament[]>(url, options);
    //     },
    //     (): Promise<any> => Promise.reject(new Error()),
    // );
    // }

    public getStageEventParticipant(stageId: any, participantId: any): Promise<Observable<StageCategory[]>> {
        // eslint-disable-next-line no-restricted-globals
        if (!stageId || !participantId || isNaN(stageId)) {
            return Promise.resolve(of([]));
        }
        return this.lang.getLang().then(
            (iso): Observable<StageCategory[]> => {
                const url = UtilsService.replace(
                    ['stageId', 'participantId'],
                    [stageId as string, participantId as string],
                    URL_CONFIG.api.getStageEventParticipant,
                );
                const params = new HttpParams().set('lang', iso);
                const options = params ? { params } : {};

                return this.http.get<StageCategory[]>(url, options).pipe(map(responseData => responseData));
            },
            (): Promise<any> => Promise.reject(new Error()),
        );
    }

    /**
     * Emit data load
     * @fires TournamentService#loaded
     * @param  {number} tournamentId
     */
    public emitLoad(tournamentId: number): void {
        this.loaded.emit(tournamentId);
    }

    public mapCategory(sportId: number): (data: CategoryInterface[]) => Observable<CategoryInterface[]> {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const _favorite = this.favorite;
        return (data): Observable<CategoryInterface[]> => {
            if (_.isArray(data)) {
                data.sort((a, b): number => {
                    const aEx = _favorite.existsTournament(sportId, a.tournament_id) ? 1 : 0;
                    const bEx = _favorite.existsTournament(sportId, b.tournament_id) ? 1 : 0;
                    return bEx - aEx;
                });
            }
            return of(data);
        };
    }

    public map(data: any): Observable<any> {
        return of(data);
    }

    public async getParticipant(
        participantId: any,
    ): Promise<Observable<ParticipantInterface> | Error> {
        const url = UtilsService.replace(
            'participantId',
            participantId as string,
            URL_CONFIG.api.getGolfParticipant,
        );
        return this.lang.getLang().then(
            (iso): Observable<ParticipantInterface> => {
                const params = new HttpParams()
                    .set('lang', iso)
                    .set('id', participantId);
                const options = params ? { params } : {};
                return this.http.get<ParticipantInterface>(url, options).pipe(
                    catchError(this.common.errorCallback2()), // then handle the error
                    tap(this.common.networkOnline()),
                    map((p: ParticipantInterface) => {
                        if (p.players) {
                            p.players = p.players?.filter(value => value.name);
                        }

                        return p;
                    }),
                );
            },
            (er): Promise<Error> => Promise.reject(new Error(er)),
        );
    }
}
