import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BaseComponent } from '@components/base.component';

import { HoleStatisticsRound } from '@interfaces/golf.interface';

@Component({
    selector: 'app-golf-hole-statistics',
    templateUrl: './golf-hole-statistics.component.html',
})
export class GolfHoleStatisticsComponent extends BaseComponent {
    @Input()
    public data: HoleStatisticsRound[];

    public constructor(
        public deviceService: DeviceDetectorService,
        // private router: Router,
    ) {
        super();
    }
}
