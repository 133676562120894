import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentModule } from '@components/component.module';
// import { GolfEventParticipantComponent } from
// '@pages/page-golf/golf-event-participant/golf-event-participant.component';
import { PageGolfComponent } from '@pages/page-golf/page-golf.component';
import { GolfListComponent } from '@pages/page-golf/golf-list/golf-list.component';
// import { GolfEventComponent } from '@pages/page-golf/golf-event/golf-event.component';

import { GolfRoutingModule } from './golf-routing.module';

import { SharedModule } from '@/shared/shared.module';

@NgModule({
    declarations: [
        // GolfEventParticipantComponent,
        PageGolfComponent,
        GolfListComponent,
        // GolfEventComponent,
    ],
    imports: [CommonModule, SharedModule, GolfRoutingModule, ComponentModule],
})
export class GolfModule {}
