import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BaseComponent } from '@components/base.component';

import { GolfTeeTime } from '@interfaces/golf.interface';

@Component({
    selector: 'app-golf-tee-times',
    templateUrl: './golf-tee-times.component.html',
})
export class GolfTeeTimesComponent extends BaseComponent {
    @Input()
    public data: GolfTeeTime[];

    public constructor(
        public deviceService: DeviceDetectorService,
    ) {
        super();
    }
}
