<app-header
    [sportId]="sportId"
    [sportName]="sportName"
    [date]="date"
    [eventListLink]="['/page', 'golf', 'golf-list']"
    [eventType]="'tournaments'"
></app-header>

<main role="main">
    <router-outlet></router-outlet>
</main>

<app-footer [sportName]="sportName"></app-footer>
