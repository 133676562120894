/* eslint-disable eqeqeq */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer, of, merge, Observable } from 'rxjs';
import { FormControl, AbstractControl, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';

import { PageService } from '@services/page.service';

import { UtilsService } from '@services/utils.service';

import { ClientService } from '@services/client.service';

import { LangService } from '@services/lang.service';

import { InfoService } from '@services/info.service';

import { GolfService } from '@services/golf.service';

import { GolfTournament } from '@interfaces/golf.interface';

import $ from '../../../shared/jquery';
import { BaseComponent } from '../../base.component';

import { MatchData, MatchState } from '../../../interfaces/match-data.interface';


import SPORT_CONFIG from '../../../config/sport.config';
import { environment } from '../../../../environments/environment';

const MATCH_LIMIT = 100;

@Component({
    selector: 'app-golf-tournament',
    templateUrl: './golf-tournament.component.html',
})
export class GolfTournamentComponent extends BaseComponent implements OnInit, OnDestroy {
    public sportId: number;

    public sportName: string;

    public tournamentId: number;

    public data: GolfTournament[];

    public tournament: GolfTournament | undefined;

    public finishedLimit: number = SPORT_CONFIG.tournament.roundLimit;

    public notStartedLimit: number = SPORT_CONFIG.tournament.roundLimit;

    private subscriber: Subscription;

    private subscriberReload: Subscription;

    public matchId: number | null = null;

    public tracker: boolean = false;
    public stream: boolean = false;

    public reverseSeason: any[] = [];

    public normalizeNotStarted: MatchData[][] = [];

    public normalizeFinished: MatchData[][] = [];

    public normalizeFinishedByDate: MatchData[][];

    public normalizeNotStartedByDate: MatchData[][];

    public matchState: MatchState;

    public actualSeasonProgress: number = 0;

    public progress: { [prop: string]: any } | null = null;

    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    /**
     * If true this tournament matches has no rounds
     * Separate info for finished and not started
     */
    public isNoRoundF: boolean = false;

    public isNoRoundNS: boolean = false;

    public activeTab: number;

    private first: boolean = true;

    private limitMax: number = MATCH_LIMIT;

    /**
     * Detect if tournament is in all subtournaments mode
     */
    public allMode: boolean = false;

    public subTournamentName: string = '';

    private sidQuery: number | null = null;

    private showFirstTable = false;

    /**
     * Id of selected season
     */
    public seasonControl: AbstractControl;

    public subTournamentControl: AbstractControl;

    public showList = true;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        public client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public dialog: MatDialog,
        private deviceService: DeviceDetectorService,
        private golfService: GolfService,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.seasonControl = new FormControl('', [Validators.required]);
        this.subTournamentControl = new FormControl(0, [Validators.required]);

        merge(
            this.seasonControl.valueChanges.pipe(
                switchMap(
                    (val): Observable<any> => {
                        if (!this.first) {
                            this.subTournamentControl.setValue(0);
                        }

                        return of(val);
                    },
                ),
            ),
            this.subTournamentControl.valueChanges,
        ).subscribe((): void => {
            if (this.subscriber instanceof Subscription) {
                this.subscriber.unsubscribe();
            }
            if (this.subscriberReload instanceof Subscription) {
                this.subscriberReload.unsubscribe();
            }

            if (!this.first) {
                this.normalizeFinished = [];
                this.normalizeNotStarted = [];
            }

            this.first = true;
            this.reset();

            // this.normalizeFinishedByDate = [];
            // this.normalizeNotStartedByDate = [];
            this.activeTabFce(1);
            this.start();
            this.reload();
        });

        this.router.paramMap.subscribe((param): void => {
            this.reset();
            this.first = true;
            this.reverseSeason.splice(0);

            if (param.has('sid')) {
                this.subTournamentControl.setValue(+param.get('sid')!);
                this.sidQuery = +param.get('sid')!;
            } else {
                this.subTournamentControl.setValue(0);
                this.sidQuery = null;
            }

            if (param.has('table')) {
                this.showFirstTable = true;
            }

            this.data = [];

            this.normalizeFinished = [];
            this.normalizeNotStarted = [];
            this.normalizeFinishedByDate = [];
            this.normalizeNotStartedByDate = [];

            // $$('html, body').animate({ scrollTop: 0 }, 1000);
            if (param.has('tournament')) {
                this.tournamentId = +param.get('tournament')!;
                this.seasonControl.setValue('');
                [this.sportId, this.sportName] = UtilsService.parseSportUrl(param.get('sport')!);
                this.ngOnDestroy();
                this.reload();
            } else {
                this.error();
            }
        });
        setTimeout((): void => {
            $('#tournamentTab li:first-child a').tab('show');
        }, 100);

        this.golfService.golfParticipantOpener$.subscribe((data) => {
            if (this.deviceService.isMobile() && data.participant) {
                this.showList = false;
            } else {
                this.showList = true;
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }

        $('[data-toggle="tooltip"]').tooltip('hide');
    }

    public trackByFnSeason(index: number, item: Record<string, any>): number {
        return item.season_id;
    }

    public trackByFnSubtournament(index: number, item: Record<string, any>): number {
        return item.sub_tournament_id;
    }

    /**
     * Start reloading data
     * @return {void}
     */
    private reload(): void {
        this.subscriberReload = timer(0, environment.matchTournamentReloadTime)
            .pipe(switchMap((val): Observable<any> => of(val)))
            .subscribe((): void => {
                this.load();
            });
    }

    /**
     * Toggle aside menu
     * @return {void}
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_tournament').subscribe((): void => {
            const Tournament = this.data && this.data[0] ? this.data[0].name : '';
            const Sport = this.translate.instant(`sport.${this.sportName}`);
            const Trans = this.translate.instant('web.title_tournament');
            const Desc = this.translate.instant('web.description_tournament');
            const Client = this.client.getVName();
            this.setTitle(
                {
                    title: `${Tournament} - ${Trans} | ${Client}`,
                    description: `${Tournament} - ${Sport} ${Desc}`,
                },
                true,
            );
        });
    }

    /**
     * Open tab
     * @param {string} tab
     */
    public tab(tab: string): void {
        if (tab === 'results') {
            this.activeTabFce(2);
        }
        if (tab === 'future') {
            this.activeTabFce(3);
        }

        setTimeout((): void => {
            $(`#tournamentTab li a[href="#${tab}"]`).tab('show');
            // $$('html, body').animate({ scrollTop: 0 }, 1000);
        }, 100);
    }

    public activeTabFce(tab: number): void {
        this.activeTab = tab;

        this.finishedLimit = SPORT_CONFIG.tournament.roundLimitNoRounds;
        this.notStartedLimit = SPORT_CONFIG.tournament.roundLimitNoRounds;

        if (!this.allMode && !this.isNoRoundF) {
            this.finishedLimit = SPORT_CONFIG.tournament.roundLimit;
        }
        if (!this.allMode && !this.isNoRoundNS) {
            this.notStartedLimit = SPORT_CONFIG.tournament.roundLimit;
        }
    }

    /**
     * Check of page loading is finished
     * @param  {string} type
     * @return  {boolean}
     */
    public isDone(type: string): boolean {
        if (type === 'finished') {
            if (!this.allMode && !this.isNoRoundF && this.normalizeFinished.length <= this.finishedLimit) {
                return true;
            }
            // if ((this.allMode || this.isNoRoundF) && Number(this.data!.data.finished.length) <= this.finishedLimit) {
            //     return true;
            // }
        }
        if (type === 'not_started') {
            if (!this.allMode && !this.isNoRoundNS && this.normalizeNotStarted.length <= this.notStartedLimit) {
                return true;
            }
            // if (
            //     (this.allMode || this.isNoRoundNS) &&
            //     Number(this.data!.data.not_started.length) <= this.notStartedLimit
            // ) {
            //     return true;
            // }
        }

        return false;
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public get tournamentLink(): boolean {
        return SPORT_CONFIG.tournamentLink.includes(this.sportName);
    }

    /**
     * Load tournament data
     *
     * @return {void}
     */
    protected async load(data: Observable<GolfTournament[]> | null = null): Promise<any> {
        try {
            data = await this.golfService.getTournamentDetail(this.tournamentId);

            this.subscriber = data.subscribe(
                (val: GolfTournament[]): void => {
                    this.data = [...val];
                    if (val.length > 0) {
                        this.tournament = { ...val[0] as any, participants: [] };
                    }
                    this.createTitle();

                    // if (this.first) {
                    //     this.openDefault();
                    // }
                    // this.first = false;

                    this.finished(true);
                },
                (): void => {
                    if (this.first) {
                        this.error(true);
                    }
                },
            );
        } catch (e) {
            if (this.first) {
                this.error(true);
            }
        }
    }
}
