<ng-container *ngFor="let round of data; trackBy: trackByIndex">

    <div *ngIf="round.pairings.length > 1" class="mb-4">

        <h3 class="ml-2"><ng-container *ngIf="round.playoff">PLAYOFF - </ng-container>{{ round.round }}. {{ 'web.round' | translate }}</h3>

        <div class="d-flex table-scrollable">
            <div class="table-scrollable__fixed">
                <div class="table-scrollable__fixed-shadow"></div>
                <table class="table-scrollable__fixed__table stage-table golf-table-list">
                    <thead>
                    <tr class="bg-light">
                        <th class="table-scrollable__table-th font-weight-normal pl-2">{{ 'web.time' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        class="table-scrollable__table-row app-cursor"
                        *ngFor="let pairing of round.pairings; trackBy: trackByIndex"
                    >
                        <td class="table-scrollable__table-td pl-2">
                            {{ pairing.time | livDate : 'localDateTimeShort' }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-scrollable__data flex-grow-1" tableScroll>
                <table class="table-scrollable__data__table stage-table golf-table-list">
                    <thead>
                    <tr class="bg-light">

                        <th *ngFor="let participant of round.pairings[1].participants; trackBy: trackByIndex"
                            class="table-scrollable__table-th table-scrollable__data__table-th vab"></th>

                    </tr>
                    </thead>
                    <tbody>
                    <ng-container>
                        <tr
                            class="table-scrollable__table-row app-cursor"
                            *ngFor="let pairing of round.pairings; trackBy: trackByIndex"
                        >
                            <td *ngFor="let participant of pairing.participants; trackBy: trackByIndex"
                                class="table-scrollable__table-td table-scrollable__data__table-td text-left">
                                <a [routerLink]="['/page/golf/participant/golf-62/', participant.id]" class="text-reset">
                                    <img
                                        *ngIf="participant.iso"
                                        src="/img/flags/1x1/{{ participant.iso | lowercase }}.svg"
                                        alt=""
                                        class="rounded-circle mr-2"
                                        height="20"
                                    />
                                    <img
                                        *ngIf="!participant.iso"
                                        src="/img/default-avatar.png"
                                        alt=""
                                        class="rounded-circle mr-2"
                                        height="20"
                                    />
                                    {{ participant.name }}
                                </a>
                            </td>

                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

</ng-container>
