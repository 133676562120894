<div class="container bg-light position-relative app-participant-detail">
    <div class="row bg-white p-0">
        <!-- Col Left -->
        <div class="col-lg-6 bg-white pl-0 pr-0" style="position: relative">
            <ng-container *ngIf="data as participant; else elseBlock">
                <div *ngIf="loadedError" class="pb-6">
                    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-7">
                            <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-' + sportName" /></svg>
                            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                            <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="pl-5 pt-5 pr-5 pr-md-3 pb-3 app-participant-detail__info">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="media">
                                <div class="img-circle img-circle--100 mr-3 rounded-circle">
                                    <img
                                        [src]="cdn + participant.image | responsive: 'participant':'large'"
                                        [attr.alt]="participant.name"
                                        class="app-participant-logo"
                                        height="100"
                                        width="100"
                                    />
                                </div>
                                <div class="media-body">
                                    <div class="d-flex icon-wrappe r justify-content-start flex-wrap">
                                        <h1 class="mb-2 mr-3" data-cy="participant-name">
                                            {{ participant.name }}
                                            <app-favorite [participantId]="data.id" [type]="'participant'">
                                                <span notactive>{{
                                                    'web.add_participant_to_favorites' | translate
                                                }}</span>
                                                <span active>{{
                                                    'web.remove_participant_from_favorites' | translate
                                                }}</span>
                                            </app-favorite>
                                        </h1>
                                        <img
                                            *ngIf="participant.country_flag"
                                            class="mr-3 mb-2"
                                            [src]="cdn + participant.country_flag"
                                            [attr.alt]=""
                                            height="40"
                                        />
                                        <div class="break-md"></div>
                                        <div class="mb-3 mb-lg-0">
                                            <ng-container
                                                ><app-last-matches
                                                    [limit]="6"
                                                    [matches]="formReverse"
                                                ></app-last-matches
                                            ></ng-container>
                                        </div>
                                    </div>
                                    <div
                                        class="mb-2 h5 d-flex"
                                        *ngIf="participant.tournament && participant.tournament.length > 0"
                                    >
                                        <span *ngIf="!isTennis" class="font-weight-bold mr-1">
                                            <a
                                                [routerLink]="[
                                                    '/page/sport/event',
                                                    sportName + '-' + sportId,
                                                    participant.tournament[0]?.tournament_id
                                                ]"
                                                class="text-reset"
                                            >
                                                {{ participant.tournament[0].sub_tournament_name }}</a
                                            >
                                        </span>
                                        <span>({{ participant.tournament[0].category_name }})</span>
                                        <!-- <div class="img-circle img-circle--20 ml-2 rounded-circle">
                                <img src="img/flags/4x3/es.svg" alt="Flag of Spain" height="20">
                              </div> -->
                                    </div>
<!--                                    <div>-->
<!--                                        <a-->
<!--                                            *ngFor="let t of uniqueTournaments; trackBy: trackByIndex"-->
<!--                                            class="crumb-inline crumb-inline__link"-->
<!--                                            [routerLink]="['/page/sport/event/', sportName + '-' + sportId, t.id]"-->
<!--                                            >{{ t.name }}</a-->
<!--                                        >-->
<!--                                    </div>-->

                                    <div class="ng-star-inserted" *ngIf="participant.venue">
                                        <span class="ng-star-inserted" *ngIf="participant.venue?.stadium">
                                            <span class="text-gray">{{ 'web.home_stadium' | translate }}:</span>
                                            {{ participant.venue?.stadium }}
                                            <ng-container *ngIf="participant.venue.place">
                                                ({{ participant.venue.place }})
                                            </ng-container>
                                            <ng-container *ngIf="client.use('map') && participant.venue.stadium_coords">
                                                -
                                                <a class="link ext-link app-cursor" (click)="map = !map"
                                                    >{{ 'web.map' | translate }} &nbsp;</a
                                                >
                                            </ng-container>
                                        </span>
                                        <span class="ng-star-inserted" *ngIf="participant.venue?.stadium_capacity">
                                            <span class="text-gray"
                                                >{{ 'web.home_stadium_capacity' | translate }}:</span
                                            >
                                            {{ participant.venue?.stadium_capacity | appnumber }}
                                        </span>
                                    </div>

                                    <div class="text-gray">
                                        <table>
                                            <tr *ngIf="participant?.ranking">
                                                <td class="pr-lg-4">
                                                    <div class="d-block d-md-inline app-word-nowrap">
                                                        <ng-container>{{ 'web.' + participant.ranking.name | translate }}
                                                            <span class="font-weight-bold text-body">
                                                                <br />
                                                                #{{ participant.ranking.rank }} ({{ participant.ranking.year }})
                                                            </span>
                                                            <span class="px-2"></span>
                                                        </ng-container>
                                                    </div>
                                                </td>
                                            </tr>
                                            <ng-container
                                                *ngIf="participant.meta?.main && participant.meta?.main?.length > 0"
                                            >
                                                <ng-container *ngFor="let pData of metaMain; trackBy: trackByIndex">
                                                    <tr>
                                                        <ng-container *ngFor="let row of pData; trackBy: trackByIndex">
                                                            <td valign="top" class="pr-lg-4">
                                                                <span class="app-word-nowrap text-gray">
                                                                    {{ row.translation | translate }}:
                                                                </span>
                                                                <br />
                                                                <div
                                                                    class="d-block d-md-inline font-weight-bold text-body"
                                                                >
                                                                    <ng-container [ngSwitch]="row.name">
                                                                        <ng-container *ngSwitchCase="'birthdate'">
                                                                            {{ row.value | livDate: 'birthdate' }} ({{
                                                                                row.value | livDate: 'age'
                                                                            }}
                                                                            {{
                                                                                'web.years-old' | translate | transinit
                                                                            }})
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchCase="'dominant-hand'">
                                                                            {{ row.value | translate }}
                                                                        </ng-container>

                                                                        <ng-container *ngSwitchDefault>
                                                                            {{ row.value }}
                                                                        </ng-container>
                                                                    </ng-container>

                                                                    <!-- <ng-container *ngIf="row.type === 'birthdate'">
                                                            {{ row.value | livDate: 'birthdate' }} ({{
                                                                row.value | livDate: 'age'
                                                            }}
                                                            {{ 'web.years-old' | translate | transinit }})
                                                        </ng-container>

                                                        <ng-container *ngIf="row.type !== 'birthdate'">{{
                                                            row.value
                                                        }}</ng-container> -->
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-md-2 text-md-right mt-2 mt-md-auto shared-btn-parent"
                            *ngIf="client.use('share')"
                        >
                            <div class="d-flex flex-row-reverse flex-md-column align-items-end">
                                <div class="dropdown ml-3 mb-md-2 dropdown-share">
                                    <button
                                        class="btn btn-light dropdown-share-btn"
                                        (click)="shbClick($event)"
                                        [class.app-share-button--active]="shareBtnAct"
                                        type="button"
                                        id="dropdown-share-btn"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <svg class="icon"><use xlink:href="#icon-share" /></svg>
                                        <div class="app-share-button__links app-share-button__links--alternate">
                                            <app-shared-buttons [max]="sharedMax"></app-shared-buttons>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="app-share-button app-cursor" (click)="shbClick($event);" [class.app-share-button--active]="shareBtnAct">
                         <svg class="icon fa-share-alt"><use href="#icon-share"/></svg>
                        <div class="app-share-button__links app-share-button__links--alternate">
                             <app-shared-buttons [max]="sharedMax"></app-shared-buttons>
                        </div>
                    </div> -->
                    </div>
                </div>

                <div class="nav-wrapper app-participant-detail__nav" style="position: relative">
                    <!-- <div class="app-mob-nav-shadow"></div> -->
                    <ul class="nav nav-tabs nav-tabs--shadows font-weight-bold pt-3" id="tabs-left" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active px-3 px-md-5"
                                [class.active]="activeTab == 1"
                                id="tab1"
                                data-toggle="tab"
                                href="#tab1-content"
                                (click)="activeTabFce(1)"
                                role="tab"
                                aria-controls="tab1-content"
                                aria-selected="true"
                                >{{ 'web.golf-tournaments' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="participant.meta.basic && participant.meta.basic.length > 0">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="basic"
                                [class.active]="activeTab == 5"
                                id="tab5"
                                data-toggle="tab"
                                href="#tab5-content"
                                role="tab"
                                (click)="activeTabFce(5)"
                                aria-controls="tab5-content"
                                aria-selected="false"
                                >{{ 'web.basic' | translate | transinit }}</a
                            >
                        </li>
                        <li
                            class="nav-item"
                            *ngIf="participant.meta.statistics && participant.meta.statistics.length > 0"
                        >
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="stats"
                                [class.active]="activeTab == 6"
                                id="tab6"
                                data-toggle="tab"
                                href="#tab6-content"
                                role="tab"
                                (click)="activeTabFce(6)"
                                aria-controls="tab6-content"
                                aria-selected="false"
                                >{{ 'web.statistics' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="participant.news?.length > 0 && client.use('news')">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="players"
                                [class.active]="activeTab == 7"
                                id="tab7"
                                data-toggle="tab"
                                href="#tab7-content"
                                role="tab"
                                (click)="activeTabFce(7)"
                                aria-controls="tab7-content"
                                aria-selected="false"
                                >{{ 'web.news' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item" *ngIf="participant.balance.length > 0">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="golfBalance"
                                [class.active]="activeTab == 8"
                                id="tab8"
                                data-toggle="tab"
                                href="#tab8-content"
                                role="tab"
                                (click)="activeTabFce(8)"
                                aria-controls="tab8-content"
                                aria-selected="false"
                                >{{ 'web.golf_balance' | translate | transinit }}</a
                            >
                        </li>
                        <li *ngIf="hasStats" class="nav-item">
                            <a
                                class="nav-link px-3 px-md-5"
                                data-cy="participantStats"
                                [class.active]="activeTab == 9"
                                id="tab8"
                                data-toggle="tab"
                                href="#tab9-content"
                                role="tab"
                                (click)="activeTabFce(9)"
                                aria-controls="tab9-content"
                                aria-selected="false"
                                >{{ 'web.participant_stats' | translate | transinit }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="tab-content bg-white px-0 px-lg-3 py-3 position-relative">
                    <div
                        class="tab-pane active"
                        [class.active]="activeTab == 1"
                        id="tab1-content"
                        role="tabpanel"
                        aria-labelledby="tab1"
                    >

                        <!--Live matches-->
                        <ng-container *ngIf="data.data.live && data.data.live.length > 0">
                            <h6
                                class="table-sport__head bg-dark app-text-dark-tournament bg-primary--light"
                                data-toggle="collapse"
                                data-target="#collapse-1-live"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text app-text-dark-tournament">{{
                                    'web.live_matches' | translate
                                    }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-1-live">
                                <app-golf
                                    *ngFor="let golfTournament of data.data.live"
                                    [golfTournament]="golfTournament"
                                    [categoryName]="golfTournament.name"
                                    [sportId]="sportId"
                                    [sportName]="sportName"
                                ></app-golf>
                            </div>
                        </ng-container>
                        <!--End Live matches-->

                        <!--Participant not started matches-->
                        <ng-container *ngIf="data.data.not_started && participant.data.not_started.length > 0">
                            <h6
                                class="table-sport__head bg-dark text-white"
                                data-toggle="collapse"
                                data-target="#collapse-1-not_started"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text">{{ 'web.not_started_tournaments' | translate }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-1-not_started">
                                <app-golf
                                    *ngFor="let golfTournament of data.data.not_started"
                                    [golfTournament]="golfTournament"
                                    [categoryName]="golfTournament.name"
                                    [sportId]="sportId"
                                    [sportName]="sportName"
                                ></app-golf>
                                <div class="text-center mt-3 mb-4">
                                    <button
                                        class="btn btn-outline-secondary btn-sm px-5"
                                        (click)="tab('participantfuture')"
                                    >
                                        {{ 'web.more_results' | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <!--End Participant notstarted matches-->

                        <!--Participant latest matches-->
                        <ng-container *ngIf="data.data.finished && data.data.finished.length > 0">
                            <h6
                                class="table-sport__head bg-dark text-white"
                                data-toggle="collapse"
                                data-target="#collapse-1-latest"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-today"
                            >
                                <span class="table-sport__head__text">{{ 'web.latest_tournaments' | translate }}</span>
                            </h6>
                            <div class="collapse show" id="collapse-1-latest">

                                <app-golf
                                    *ngFor="let golfTournament of data.data.finished"
                                    [golfTournament]="golfTournament"
                                    [categoryName]="golfTournament.name"
                                    [sportId]="sportId"
                                    [sportName]="sportName"
                                ></app-golf>

<!--                                <div class="text-center mt-3 mb-4">-->
<!--                                    <button-->
<!--                                        class="btn btn-outline-secondary btn-sm px-5"-->
<!--                                        (click)="tab('participantresults')"-->
<!--                                    >-->
<!--                                        {{ 'web.more_results' | translate }}-->
<!--                                    </button>-->
<!--                                </div>-->
                            </div>
                        </ng-container>

                        <div *ngIf="data.data.finished.length === 0
                                    && data.data.not_started.length === 0
                                    && data.data.live.length === 0
                                    " class="text-center pt-7 app-match-no-data">
                            <svg class="icon icon--64 mb-4">
                                <use [attr.xlink:href]="'#icon-' + sportName" />
                            </svg>
                            <h4 class="font-weight-bold">
                                {{ 'web.no_tournaments_found' | translate }}
                            </h4>
                        </div>
                        <!--End Participantlatest matches-->

                    </div>




                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 5"
                        *ngIf="participant.meta.basic && participant.meta.basic.length > 0"
                        id="tab5-content"
                        role="tabpanel"
                        aria-labelledby="tab5"
                    >
                        <div class="table-responsive">
                            <div class="match-info__table small pb-2 text-center">
                                <div class="match-info__table__row player-record bg-dark text-white">
                                    <div class="match-info__table__cell text-left font-weight-bold">&nbsp;</div>
                                    <div class="match-info__table__cell text-left font-weight-bold">&nbsp;</div>
                                </div>

                                <div
                                    *ngFor="let row of participant.meta.basic; trackBy: trackByIndex"
                                    class="match-info__table__row app-data-row-data player-record"
                                    data-cy="participant-stats-basic"
                                >
                                    <div class="match-info__table__cell text-left font-weight-bold border-top">
                                        {{ row.translation | translate }}:
                                    </div>
                                    <div class="match-info__table__cell text-left font-weight-bold border-top">
                                        {{ row.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 6"
                        *ngIf="participant.meta.statistics && participant.meta.statistics.length > 0"
                        id="tab6-content"
                        role="tabpanel"
                        aria-labelledby="tab6"
                    >
                        <div class="table-responsive">
                            <div class="match-info__table small pb-2 text-center">
                                <div class="match-info__table__row player-record bg-dark text-white">
                                    <div class="match-info__table__cell text-left font-weight-bold">&nbsp;</div>
                                    <div class="match-info__table__cell text-left font-weight-bold">&nbsp;</div>
                                </div>

                                <div
                                    *ngFor="let row of participant.meta.statistics; trackBy: trackByIndex"
                                    class="match-info__table__row app-data-row-data player-record"
                                    data-cy="participant-stats-stats"
                                >
                                    <div class="match-info__table__cell text-left font-weight-bold border-top">
                                        {{ row.translation | translate }}:
                                    </div>
                                    <div class="match-info__table__cell text-left font-weight-bold border-top">
                                        {{ row.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="tab-pane"
                        [class.active]="activeTab == 7"
                        *ngIf="participant.news?.length > 0"
                        id="tab7-content"
                        role="tabpanel"
                        aria-labelledby="tab7"
                    >
                        <app-news [data]="participant.news"></app-news>
                    </div>
                    <div
                        class="tab-pane"
                        *ngIf="activeTab == 8"
                        [class.active]="activeTab == 8"
                        id="tab8-content"
                        role="tabpanel"
                        aria-labelledby="tab8"
                    >
                        <app-golf-balance [data]="participant.balance"></app-golf-balance>
                    </div>
                </div>
                <google-modal [mapCoordinates]="participant.venue?.stadium_coords" [(visible)]="map"></google-modal>
            </ng-container>
            <ng-template #elseBlock>
                <app-placeholder-tournament [progress]="false"></app-placeholder-tournament>
            </ng-template>
        </div>
        <!-- Col Right -->
        <div class="col-lg-6 d-none d-lg-block">

            <div [class.sticky-parent]="stickyDetail">
                <app-golf-participant-tournament></app-golf-participant-tournament>

                <app-ranking *ngIf="!deviceService.isMobile()" [sportName]="sportName" [sportId]="sportId"></app-ranking>
            </div>

            <div class="d-flex bg-white align-items-center app-form-canvas" *ngIf="data.season_stats">
                <div class="col">
                    <hr />
                    <nav>
                        <ol class="app-small-nav">
                            <li
                                class="crumb"
                                *ngFor="let stats of data.season_stats | keys; first as isFirst; trackBy: trackByIndex"
                            >
                                <a
                                    [routerLink]="['./']"
                                    [queryParams]="{ statsKey: stats.key }"
                                    class="crumb__link"
                                    [class.active]="
                                        seasonStatsParam === stats.key || (seasonStatsParam === null && isFirst)
                                    "
                                    >{{ stats.value.tournamentName }}</a
                                >
                            </li>
                        </ol>
                    </nav>
                    <ng-container
                        *ngFor="let stats of data.season_stats | keys; first as isFirst; trackBy: trackByIndex"
                    >
                        <app-participant-season-stats
                            *ngIf="seasonStatsParam === stats.key || (seasonStatsParam === null && isFirst)"
                            [stats]="stats.value"
                        ></app-participant-season-stats>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <aside class="nav-sports d-none d-xl-block" *ngIf="client.use('sportQuickNav')">
        <nav class="nav flex-column bg-light">
            <app-sport-navigation type="aside" [limit]="asideLimit" [sportId]="sportId"></app-sport-navigation>
            <a
                class="nav-link nav-link--more text-uppercase text-reset"
                [class.nav-link--more]="isAsideDefault()"
                [class.nav-link--less]="!isAsideDefault()"
                (click)="toggleAssideLimit()"
            >
                <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                <span *ngIf="!isAsideDefault()">{{ 'web.less' | translate }}</span>
            </a>
        </nav>
    </aside>
    <aside [class.d-xl-block]="client.use('sportQuickNav')" class="nav-sports nav-sports--extra d-none">
        <nav class="nav flex-column nav-side-menu bg-light">
            <app-side-menu-navigation [sportId]="sportId" [sportName]="sportName"></app-side-menu-navigation>
        </nav>
    </aside>
</div>
